import axios from 'axios'

// export const API_BASE_URL = 'https://apis.sketchplay.io'
export const API_BASE_URL = 'https://stage-apis.sketchplay.io'
//export const API_BASE_URL = 'http://localhost:1337'

const instance = axios.create()

instance.defaults.baseURL = API_BASE_URL
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.timeout = 1000 * 30

// request interceptor
instance.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// response interceptor
instance.interceptors.response.use(
    (response) => {
        return response.data || response
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default instance
