import { useEffect, useState } from 'react'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { CssBaseline, Grid, Drawer, Dialog, DialogTitle, DialogActions, Button, Typography } from '@mui/material'
import Header from '@components/Header'
import EditProfile from "../../components/EditProfile"
import ChangePassword from "../../components/ChangePassword"
import DrawerMenu from '@components/DrawerMenu'
import NotificationPopup from '@components/NotificationPopup'
import PageLoader from '@components/PageLoader'
import ToastMessage from '@components/ToastMessage'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
    BUCKET_PAGE,
    COACH_PLAYER_CONTENT_PAGE,
    PLAYER_FILM_ROOM_PAGE,
    PLAYER_SOCIAL_WALL_PAGE,
} from '@routes/constants'
import { SET_DRAWER, getOrgData, SET_BUCKET, LOGOUT_USER } from '@store/actions'
import Logo from '@components/Logo'
import SocialIcon from '@assets/images/icons/social.png'
// import { COACH_PLAYER_CONTENT_PAGE } from '@routes/constants'
import { CALENDAR_PAGE } from '@routes/constants'
import { SUBSCRIPTION_PAGE } from '@routes/constants'
import { ROOT_PAGE } from '@routes/constants'
import { CHANGE_PASSWORD_PAGE } from '@routes/constants'
import bucketIcon from '@assets/images/icons/bucket.svg'
import calendarIcon from '@assets/images/icons/calendar.svg'
import moneyIcon from '@assets/images/icons/money.svg'
import userIcon from '@assets/images/icons/user.svg'
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
// import SegmentSharpIcon from '@mui/icons-material/SegmentSharp';
import './style.css'
import { EDIT_PROFILE_PAGE, MANAGEUSER_PAGE } from '../../routes/constants'


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }),
    })
)

const MainLayout = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [openLeftDrawer, setOpenLeftDrawer] = useState(true);
    const [userDrawer, setUserDrawer] = useState(false);
    const [step, setStep] = useState(0);
    const [prevStep, setPrevStep] = useState(3);
    const showLoader = useSelector((state) => state.theme.showLoader)
    const drawerOpened = useSelector((state) => state.theme.drawer)
    const [activeItem, setActiveItem] = useState(null);
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )
    const [menuItems, setMenuItems] = useState([])

    const { user } = useSelector((state) => state.user || {})

    useEffect(() => {
        if (org.menu_settings && user.user_type) {
            let _menuItems = org.menu_settings[user.user_type] || []
            _menuItems = _menuItems
                .filter((menuItem) => menuItem.isWeb)
                .map((menuItem) => ({
                    ...menuItem,
                    ...(menuSettings[menuItem.id] || {}),
                }))
            setMenuItems(_menuItems)
        }
    }, [org, user])

    const handleDrawerToggle = () => {
        dispatch({ type: SET_DRAWER, data: !drawerOpened })
    }

    const handleOpenLeftDrawer = () => {
        setOpenLeftDrawer(true);
    };

    const handleCloseLeftDrawer = () => {
        setOpenLeftDrawer(false);
    };

    const UserDrawerOpen = () => {
        setUserDrawer(true);
    };

    const UserDrawerClose = () => {
        setUserDrawer(false);
    };

    const Navigate = (item) => {
        setUserDrawer(false);
        setOpenLeftDrawer(false);
    };
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const logout = () => {
        dispatch({ type: SET_BUCKET, data: [] })
        dispatch({ type: LOGOUT_USER })
        navigate(ROOT_PAGE)
    }
    // const changePassword = () => {
    //     navigate(CHANGE_PASSWORD_PAGE)
    // }

    const navigateToSubsciption = () => {
        navigate(SUBSCRIPTION_PAGE)
        setUserDrawer(false);
    }

    useEffect(() => {
        dispatch(getOrgData())
    }, [])

    const icons = {
        'player-content': {
            link: <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24' >
                <path d='M21.7,6.7c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.3-0.5-0.6-0.9-0.8l-7-4c-0.9-0.5-2.1-0.5-3,0l-7,4C3.2,5.6,2.9,5.9,2.6,6.2C2.6,6.3,2.5,6.4,2.4,6.5c0,0.1,0,0.1-0.1,0.2C2.1,7.1,2,7.5,2,8v8c0,1.1,0.6,2.1,1.5,2.6l7,4c0.3,0.2,0.7,0.3,1,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3-0.1,0.5-0.1c0.3-0.1,0.7-0.2,1-0.3l7-4c0.9-0.5,1.5-1.5,1.5-2.6v0V8C22,7.5,21.9,7.1,21.7,6.7z M11.5,3.1C11.7,3,11.8,3,12,3c0.2,0,0.3,0,0.5,0.1L19,6.8l-7,4l-7-4L11.5,3.1z M4.5,16.9C4.2,16.7,4,16.4,4,16V8.5l7,4v8L4.5,16.9z M19.5,16.9L13,20.6v-8l7-4V16C20,16.4,19.8,16.7,19.5,16.9z' />
            </svg >
        },
        'calendar': {
            link: <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24'>
                <path d='M19,3h-2V2c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H9V2c0-0.6-0.4-1-1-1S7,1.4,7,2v1H5C3.3,3,2,4.3,2,6v14c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3V6C22,4.3,20.7,3,19,3z M5,5h2v1c0,0.6,0.4,1,1,1s1-0.4,1-1V5h6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V5h2c0.6,0,1,0.4,1,1v3H4V6C4,5.4,4.4,5,5,5z M19,21H5c-0.6,0-1-0.4-1-1v-9h16v9C20,20.6,19.6,21,19,21z' />
            </svg>
        },
        'promoLinks': {
            link: <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24'>
                <g>
                    <path d='M12,8c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4C16,9.8,14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,13.1,13.1,14,12,14z' />
                    <path d='M20.3,15.4c0.1-0.2,0.3-0.4,0.7-0.4c1.7,0,3-1.3,3-3s-1.3-3-3-3h-0.2c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.5,0.2-0.8c1.2-1.2,1.2-3.1,0-4.2v0c0,0,0,0,0,0c-0.6-0.6-1.3-0.9-2.1-0.9c0,0,0,0,0,0c-0.8,0-1.6,0.3-2.2,0.9c-0.2,0.2-0.5,0.2-0.7,0.1C15.2,3.6,15,3.3,15,3c0-1.7-1.3-3-3-3S9,1.3,9,3v0.2c0,0.2-0.1,0.4-0.3,0.5c-0.1,0-0.1,0-0.2,0.1C8.3,3.9,8,3.8,7.7,3.6c-1.2-1.2-3.1-1.2-4.2,0C2.3,4.8,2.3,6.7,3.6,7.9c0.2,0.2,0.2,0.5,0.1,0.8C3.6,8.9,3.3,9.1,3,9.1c-1.7,0-3,1.3-3,3s1.3,3,3,3h0.2c0.3,0,0.5,0.2,0.6,0.4c0.1,0.2,0.1,0.5-0.2,0.8c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.5,0.9,2.1c0,0,0,0,0,0c1.2,1.2,3.1,1.2,4.3-0.1c0.2-0.2,0.5-0.2,0.8-0.1c0.3,0.1,0.4,0.3,0.4,0.7c0,1.7,1.3,3,3,3s3-1.3,3-3v-0.2c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.1,0.8,0.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1-0.1-4.3C20.3,15.9,20.2,15.6,20.3,15.4C20.3,15.4,20.3,15.4,20.3,15.4z M18.5,14.6c-0.4,1-0.2,2.1,0.6,3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c0,0,0,0,0,0c-0.3,0-0.5-0.1-0.8-0.4c-0.8-0.8-1.9-1-2.9-0.5c-1,0.4-1.6,1.4-1.6,2.4V21c0,0.6-0.4,1-1,1s-1-0.4-1-1c0,0,0-0.1,0-0.1c0-1.1-0.7-2-1.7-2.4c-0.3-0.2-0.7-0.2-1.1-0.2c-0.7,0-1.4,0.3-1.9,0.8c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0h0c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.4-0.8c0.8-0.8,1-1.9,0.5-2.9c-0.4-1-1.4-1.6-2.4-1.6H3c-0.6,0-1-0.4-1-1s0.4-1,1-1c0,0,0.1,0,0.1,0c1.1,0,2-0.7,2.4-1.7c0.4-1,0.2-2.1-0.6-3C4.5,6,4.5,5.4,4.9,5c0.4-0.4,1-0.4,1.5,0.1C7.1,5.8,8.2,6,9.1,5.7c0.1,0,0.2,0,0.3-0.1c1-0.4,1.6-1.4,1.6-2.4V3c0-0.6,0.4-1,1-1s1,0.4,1,1.1c0,1.1,0.6,2,1.6,2.4c1,0.4,2.1,0.2,3-0.6c0.2-0.2,0.4-0.3,0.7-0.3h0c0.3,0,0.5,0.1,0.7,0.3c0,0,0,0,0,0c0.4,0.4,0.4,1-0.1,1.5c-0.7,0.7-0.9,1.8-0.6,2.7c0,0.1,0,0.2,0.1,0.3c0.4,1,1.4,1.6,2.4,1.6H21c0.6,0,1,0.4,1,1s-0.4,1-1.1,1C19.9,13,18.9,13.6,18.5,14.6z' />
                </g>
            </svg>
        },
        'manage-users1': {
            link: <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24'>
                <g>
                    <path d='M13,14H5c-2.8,0-5,2.2-5,5v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C18,16.2,15.8,14,13,14z' />
                    <path d='M9,12c2.8,0,5-2.2,5-5s-2.2-5-5-5S4,4.2,4,7S6.2,12,9,12z M9,4c1.7,0,3,1.3,3,3s-1.3,3-3,3S6,8.7,6,7S7.3,4,9,4z' />
                    <path d='M20.2,14.2c-0.5-0.1-1.1,0.2-1.2,0.7c-0.1,0.5,0.2,1.1,0.7,1.2c1.3,0.3,2.2,1.5,2.2,2.9v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C24,16.7,22.5,14.7,20.2,14.2z' />
                    <path d='M16.2,2.2C15.7,2,15.2,2.3,15,2.9c-0.1,0.5,0.2,1.1,0.7,1.2c1.6,0.4,2.6,2,2.2,3.7c-0.3,1.1-1.1,1.9-2.2,2.2c-0.5,0.1-0.9,0.7-0.7,1.2c0.1,0.5,0.5,0.8,1,0.8c0.1,0,0.2,0,0.2,0c1.8-0.5,3.2-1.8,3.6-3.6C20.5,5.6,18.9,2.8,16.2,2.2z' />
                </g>
            </svg>
        },
        'subsciption': {
            link: <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24'>
                <g>
                    <path d='M13,14H5c-2.8,0-5,2.2-5,5v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C18,16.2,15.8,14,13,14z' />
                    <path d='M9,12c2.8,0,5-2.2,5-5s-2.2-5-5-5S4,4.2,4,7S6.2,12,9,12z M9,4c1.7,0,3,1.3,3,3s-1.3,3-3,3S6,8.7,6,7S7.3,4,9,4z' />
                    <path d='M20.2,14.2c-0.5-0.1-1.1,0.2-1.2,0.7c-0.1,0.5,0.2,1.1,0.7,1.2c1.3,0.3,2.2,1.5,2.2,2.9v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C24,16.7,22.5,14.7,20.2,14.2z' />
                    <path d='M16.2,2.2C15.7,2,15.2,2.3,15,2.9c-0.1,0.5,0.2,1.1,0.7,1.2c1.6,0.4,2.6,2,2.2,3.7c-0.3,1.1-1.1,1.9-2.2,2.2c-0.5,0.1-0.9,0.7-0.7,1.2c0.1,0.5,0.5,0.8,1,0.8c0.1,0,0.2,0,0.2,0c1.8-0.5,3.2-1.8,3.6-3.6C20.5,5.6,18.9,2.8,16.2,2.2z' />
                </g>
            </svg>
        },
        'social': {
            link: <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24'>
                <path d='M19,2H5C3.3,2,2,3.3,2,5v16c0,0.4,0.2,0.8,0.6,0.9C2.7,22,2.9,22,3,22c0.3,0,0.5-0.1,0.7-0.3L7.4,18H19c1.7,0,3-1.3,3-3V5C22,3.3,20.7,2,19,2z M20,15c0,0.6-0.4,1-1,1H7c-0.3,0-0.5,0.1-0.7,0.3L4,18.6V5c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1V15z' />
            </svg>
        },
        'manage-users': {
            link: <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24'>
                <path d='M21.7,6.7c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.3-0.5-0.6-0.9-0.8l-3.3-1.9c-0.1-0.1-0.3-0.2-0.5-0.3l-3.2-1.8c-0.9-0.5-2.1-0.5-3,0L7.3,3.2c-0.2,0-0.3,0.1-0.5,0.3L3.5,5.4C3.2,5.6,2.9,5.9,2.6,6.2C2.6,6.3,2.5,6.4,2.4,6.5c0,0.1,0,0.1-0.1,0.2C2.1,7.1,2,7.5,2,8v8c0,1.1,0.6,2.1,1.5,2.6l3.3,1.9c0.1,0.1,0.3,0.2,0.5,0.3l3.2,1.9c0.3,0.2,0.7,0.3,1,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3-0.1,0.5-0.1c0.3-0.1,0.7-0.2,1-0.3l3.3-1.9c0.2,0,0.4-0.1,0.5-0.3l3.3-1.9c0.9-0.5,1.5-1.5,1.5-2.6v0V8C22,7.5,21.9,7.1,21.7,6.7z M12,10.9l-7-4l2.5-1.4l4,2.3c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1l3.9-2.3L19,6.8L12,10.9z M11.5,3.1C11.7,3,11.8,3,12,3c0.2,0,0.3,0,0.5,0.1l1.9,1.1L12,5.7L9.6,4.2L11.5,3.1z M4,16v-2.3l2.5,1.4V18l-2-1.1C4.2,16.7,4,16.4,4,16z M8.5,19.2v-4.6c0-0.4-0.2-0.7-0.5-0.9l-4-2.3V8.5l7,4v8L8.5,19.2z M13,20.6v-8l7-4v2.9l-4,2.3c-0.3,0.2-0.5,0.5-0.5,0.9v4.6L13,20.6z M19.5,16.9l-2,1.1v-2.8l2.5-1.4V16C20,16.4,19.8,16.7,19.5,16.9z' />
            </svg>
        },
    }

    const menuSettings = {
        buckets: {
            icon: bucketIcon,
            link: BUCKET_PAGE,
        },
        social: {
            icon: SocialIcon,
            link: PLAYER_SOCIAL_WALL_PAGE,
        },
        subsciption: {
            icon: SocialIcon,
            link: SUBSCRIPTION_PAGE,
        },
        promoLinks: {
            icon: SocialIcon,
            link: "",
        },
        scheduler: {
            icon: calendarIcon,
            link: CALENDAR_PAGE,
            comingsoon: {
                body: 'This feature isn’t quite out of the oven. Hang with us while we prep this juicy treat. Once ready, you’ll be able to schedule On Turf Workouts right from this screen.',
                postedBy: 'The {{RG_SP_ORG_NAME}} Team',
            },
        },
        'player-content': {
            icon: userIcon,
            link: user.user_type === 'coach' ? COACH_PLAYER_CONTENT_PAGE : PLAYER_FILM_ROOM_PAGE
        },
        revenue: {
            icon: moneyIcon,
            link: SUBSCRIPTION_PAGE,
            comingsoon: {
                body: 'Once ready, you’ll be able to see all your users who have registered on desktop and mobile app. You’ll also be able to see which plan that user has subscribed to (if they’ve subscribed).',
                postedBy: 'The {{RG_SP_ORG_NAME}} Team',
            },
        },
        'manage-users': {
            icon: userIcon,
            link: MANAGEUSER_PAGE,
            comingsoon: {
                body: 'Once ready, you’ll be able to see all your users who have registered on desktop and mobile app. You’ll also be able to see which plan that user has subscribed to (if they’ve subscribed).',
                postedBy: 'The {{RG_SP_ORG_NAME}} Team',
            },
        },
        calendar: {
            icon: calendarIcon,
            link: CALENDAR_PAGE,
            comingsoon: {
                body: 'Once ready, you’ll be able to see all your users who have registered on desktop and mobile app. You’ll also be able to see which plan that user has subscribed to (if they’ve subscribed).',
                postedBy: 'The {{RG_SP_ORG_NAME}} Team',
            },
        },

    }
    const handleGoBack = () => {
        if (step === 0) {
            UserDrawerClose()
        } else if (step === 1 || step === 2) {
            setStep(0)
        }
    }
    const handleCancel = () => {
        setStep(prevStep);
    }
    const handleClick = (item) => {
        setActiveItem(item)
    }

    return (
        <>
            <Helmet
                defaultTitle="App"
                titleTemplate="%s App"
                title={org.title}
            />
            <Box className={openLeftDrawer ? 'menu-open' : "root-main"}
                sx={{
                    display: 'flex',
                    backgroundColor: theme.palette.primary.light,
                    transition: '0.22s all',
                    '& .pointer': {
                        cursor: 'pointer',
                    },
                    '& .textUnderline': {
                        textDecoration: 'underline',
                        textDecorationColor: 'secondary',
                    },
                    '& .d-flex': {
                        display: 'flex',
                    },
                    '&.menu-open': {
                        transition: '0.22s all',
                        paddingLeft: '300px',
                        '& header': {
                            paddingLeft: '300px',
                        },

                    },
                    '& header': {
                        transition: '0.22s all',
                    },
                }}
            >
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.paper,
                        transition: drawerOpened
                            ? theme.transitions.create('width')
                            : 'none',
                    }}
                >
                    <Toolbar>
                        <Header
                            leftDrawer={openLeftDrawer}
                            openUserDrawer={UserDrawerOpen}
                            leftDrawerToggle={handleOpenLeftDrawer}
                        />
                    </Toolbar>
                </AppBar>

                {/* drawer */}
                <DrawerMenu
                    drawerOpen={drawerOpened}
                    drawerToggle={handleDrawerToggle}
                />

                {/* notification popup */}
                <NotificationPopup />

                {/* main content */}
                <Main theme={theme} open={drawerOpened}>
                    <Outlet />

                </Main>
            </Box>

            <Drawer anchor="left" open={openLeftDrawer} onClose={handleCloseLeftDrawer} sx={{
                '& .MuiPaper-root': {
                    overflow: 'inherit',
                }
            }}
                variant="persistent"
            >
                <Grid container sx={{ width: 300, height: "100%", overflow: "inherit" }}>
                    <Grid item xs={12} sx={{
                        '& .box-with-menu': {
                            position: "relative"
                        }
                    }}>
                        <Box className='box-with-menu' sx={{
                            '& .aside-menu-box': {
                                width: '300px',
                                background: '#0063A8',
                                padding: '0.5rem 1rem',
                                position: 'fixed',
                                left: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: 999,
                                minHeight: '100vh',
                                overflowY: 'auto',

                            }
                        }}>
                            <Box className='aside-menu-box' sx={{
                                '& .aside-logo-box': {
                                    maxWidth: '48px',
                                    //marginBottom: '1.5rem',
                                    '& img': {
                                        width: '100%',
                                        height: 'auto'
                                    }
                                }
                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", textAlign: "center", marginBottom: '1.5rem', width: '100%' }}>
                                    <Box className='aside-logo-box' sx={{ justifyContent: "center", textAlign: "center", marginBottom: '0', marginRight: 'auto' }}>
                                        <Logo size="small" />
                                    </Box>
                                    <Box sx={{ height: '33px', width: '33px', cursor: "pointer" }} onClick={handleCloseLeftDrawer} className="pointer">
                                        <svg x='0px' y='0px' viewBox='0 0 36 36' style={{ fill: '#fff', width: '100%', height: 'auto' }}>
                                            <g>
                                                <path class='st0' d='M29.1,12.3H6.9c-1.1,0-1.9-0.9-1.9-1.9v0c0-1.1,0.9-1.9,1.9-1.9h22.2c1.1,0,1.9,0.9,1.9,1.9v0C31,11.4,30.1,12.3,29.1,12.3z' />
                                                <path class='st0' d='M29.1,19.9H15.3c-1.1,0-1.9-0.9-1.9-1.9v0c0-1.1,0.9-1.9,1.9-1.9h13.8c1.1,0,1.9,0.9,1.9,1.9v0C31,19.1,30.1,19.9,29.1,19.9z' />
                                                <path class='st0' d='M29.1,27.6h-6.9c-1.1,0-1.9-0.9-1.9-1.9v0c0-1.1,0.9-1.9,1.9-1.9h6.9c1.1,0,1.9,0.9,1.9,1.9v0C31,26.7,30.1,27.6,29.1,27.6z' />
                                            </g>
                                        </svg>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    '& .aside-menu-list ul': {
                                        margin: 0,
                                        padding: 0,
                                        listStyle: 'none',
                                        fontSize: '14px',
                                        color: '#fff',
                                        fontWeight: 500,
                                        lineHeight: 1.167,
                                        letterSpacing: '0.03em',
                                        fontFamily: 'Poppins,sans-serif,Arial'
                                    },
                                    '& .aside-menu-list ul li': {
                                        // marginBottom: ' 1.4rem'
                                        padding: "0.8rem 1rem",
                                        margin: "0 -1rem",
                                        transition: "0.5s all",
                                    },
                                    '& .aside-menu-list ul li.active': {
                                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                                    },
                                    '& .aside-menu-list ul a': {
                                        color: '#fff',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .aside-menu-list ul .menu-icon': {
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '0.5rem',
                                        opacity: '0.4',
                                    },
                                    '& .aside-menu-list ul .menu-icon svg': {
                                        fill: '#fff'
                                    },
                                    '& .aside-menu-list ul li:last-child': {
                                        marginBottom: 0
                                    }
                                }}>
                                    <Box className='aside-menu-list'>
                                        <ul>
                                            {menuItems.map((item, i) =>
                                                <>
                                                    <li key={i} onClick={() => handleClick(item)}
                                                        className={activeItem === item ? 'active' : ''}>
                                                        {item?.externalLink ?
                                                            <Box onClick={Navigate}>
                                                                <a href={item?.externalLink}
                                                                    target="_blank" rel="noopener noreferrer">
                                                                    <span className='menu-icon'>
                                                                        {icons[item.id]?.link}
                                                                    </span>
                                                                    {item.label}
                                                                </a>
                                                            </Box> :
                                                            <Box onClick={Navigate}>
                                                                <Link
                                                                    to={item?.link}
                                                                >
                                                                    <span className='menu-icon'>
                                                                        {icons[item.id]?.link}
                                                                    </span>
                                                                    {item.label}
                                                                </Link>
                                                            </Box >}
                                                    </li >
                                                </>
                                            )}
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Drawer >
            < Drawer anchor="right" open={userDrawer}
                sx={{
                    '& .MuiPaper-root': {
                        width: '35%',
                        // backgroundColor: "#F4F6F9",
                        minWidth: '340px',
                        '& .MuiPaper-root': {
                            width: '100%',
                            backgroundColor: "transparent",
                            minWidth: 'inherit',
                        }
                    },
                }} >
                <Grid container sx={{ height: "100%" }}>
                    <Grid item xs={12}>
                        <Box sx={{
                            '& .event-details-box': {
                                background: '#fff',
                                // borderRadius: '20px',
                                padding: '1.5rem',
                                boxShadow: '0 -15px 15px rgba(0, 0, 0, 0.1)',
                                fontSize: '14px',
                                // marginTop: '-40px',
                                position: 'relative',
                                zIndex: 2,
                                overflow: 'hidden',
                                color: '#000',
                                textAlign: 'center',
                            }
                        }} >
                            <Box className='event-details-box' sx={{
                                '& .event-bottom-box': {
                                    marginTop: '3rem',
                                    // padding: "1.5rem"
                                },
                                '& .event-bottom-box .btn': {
                                    padding: '1rem',
                                    display: 'block',
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    color: '#fff',
                                    border: 0,
                                    borderRadius: '5px',
                                },
                                '& .event-bottom-box .btn+.btn': {
                                    marginTop: '1rem'
                                },
                                '& .btn-black': {
                                    background: 'black',
                                    cursor: "pointer"
                                }
                            }}>
                                <Box className="event-bottom-box">
                                    <Box className="drawer-top"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            p: 1,
                                            pb: 0,
                                            position: 'fixed',
                                            zIndex: 99,
                                            top: '0',
                                            left: '0',
                                            right: '0',
                                            width: '35.5%',
                                            minWidth: '340px',
                                            marginLeft: 'auto',
                                        }}>
                                        <IconButton
                                            aria-label="back"
                                            sx={{
                                                position: 'relative',
                                                color: (theme) => theme.palette.grey[500],
                                                right: 8, bottom: 5
                                            }}
                                            onClick={handleGoBack}
                                        >
                                            {step !== 3 && <NavigateBeforeIcon sx={{ fontSize: 35, color: 'black' }} />}
                                        </IconButton>
                                        <IconButton
                                            aria-label="close"
                                            sx={{
                                                position: 'relative',
                                                color: (theme) => theme.palette.grey[500],
                                                bottom: 5
                                            }}
                                            onClick={() => {
                                                if (step !== 3) {
                                                    setStep(() => {
                                                        setPrevStep(step);
                                                        return 3;
                                                    });
                                                }
                                            }}
                                        >
                                            <CloseIcon sx={{ fontSize: 30, color: 'black' }} />
                                        </IconButton>
                                    </Box>
                                    <Box sx={{
                                        ml: 'auto',
                                        '& .user-login-info figure': {
                                            height: '100px',
                                            width: '100px',
                                            minWidth: '45px',
                                            background: '#2a2e3e',
                                            margin: 0,
                                            color: '#fff',
                                            fontSize: '22px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontWeight: 600,
                                            borderRadius: '50px',
                                            marginRight: '0.6rem',
                                            position: 'relative',
                                            overflow: 'hidden',
                                        },
                                        '& .user-login-info figure img': {
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '50px',
                                        },
                                        '& .user-login-info': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 8,
                                            mt: 12,
                                            justifyContent: 'center',
                                            "flex-direction": 'column-reverse',
                                        }
                                    }} className='pointer'>
                                        {step === 0 &&
                                            <Box className='user-login-info' sx={{
                                                '& .user-info h5': {
                                                    fontSize: '0.85rem',
                                                    color: '#212121',
                                                    fontWeight: 600,
                                                    lineHeight: 1.167,
                                                    letterSpacing: '0em',
                                                    margin: '0 0 0.2rem 0',
                                                    whiteSpace: 'nowrap',
                                                    mt: 2,
                                                },
                                                '& .user-info p': {
                                                    margin: 0,
                                                    fontSize: '0.8rem'
                                                },
                                                "#userImg": {
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover"
                                                },
                                                span: {
                                                    "display": "block",
                                                    "fontSize": "37px",
                                                    "fontWeight": "bold",
                                                    "lineHeight": "99px",
                                                    "textAlign": "center",
                                                    "height": "100px",
                                                    "width": "100px",
                                                }
                                            }}>
                                                <Box className='user-info' sx={{ paddingRight: '15px' }}>
                                                    <h5>{user?.first_name} {user?.last_name}</h5>
                                                    {user?.user_type === 'player' ?
                                                        <p>{"User"}</p> :
                                                        <p>{user?.user_type?.charAt(0)?.toUpperCase() + user?.user_type?.slice(1)}</p>}
                                                </Box>
                                                {user?.image ?
                                                    <figure><img id="userImg" src={user.image} alt="profile-img" /></figure> :
                                                    <Box component="span"
                                                        sx={{
                                                            "backgroundColor": "#000000",
                                                            "justifyContent": "center",
                                                            "margin": "0",
                                                            "height": "50px",
                                                            "width": "50px",
                                                            "minWidth": "50px",
                                                            "color": "#ffffff",
                                                            "borderRadius": "50rem",
                                                            "position": "relative",
                                                            "overflow": "hidden",
                                                            "border": "3px solid #fff",
                                                            "boxShadow": " 0 0 15px rgba(0, 0, 0, 0.25)",
                                                            "textTransform": "uppercase"
                                                        }}>{`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
                                                    </Box>}
                                            </Box>
                                        }
                                    </Box>
                                    {step === 0 && (
                                        <>
                                            {user.user_type === 'player' && (
                                                <button className='btn btn-black' onClick={navigateToSubsciption}>My Subscription</button>
                                            )}
                                            <button className='btn btn-black' onClick={() => { setStep(1) }}>Edit Profile</button>
                                            <button className='btn btn-black' onClick={() => { setStep(2) }}>Change Password</button>
                                            <button className='btn btn-black' onClick={handleClickOpen}>Logout</button>
                                        </>
                                    )}
                                </Box>
                                {step === 1 && <EditProfile UserDrawerClose={UserDrawerClose} setStep={setStep} />}
                                {step === 2 && <ChangePassword UserDrawerClose={UserDrawerClose} setStep={setStep} />}
                                {step === 3 &&
                                    <>
                                        <Box sx={{
                                            minHeight: 'calc(100vh - 128px)',
                                            position: 'relative',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <Grid item xs={12} sx={{ m: 'auto' }}>
                                                <Typography variant="h4" sx={{ textAlign: 'center', fontSize: '17px', fontWeight: '700', mb: 1 }}>Are you sure you want to cancel?</Typography>
                                                <Typography variant="body2" sx={{ textAlign: 'center' }}>You will lose any unsaved content</Typography>
                                            </Grid>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'space-between',
                                                    //position: "absolute",
                                                    //bottom: 50,
                                                    // width: '100%',
                                                    marginTop: 'auto',
                                                }}
                                            >
                                                {/* {showError && <Alert severity="error">{showError}</Alert>} */}
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    fullWidth={false}
                                                    onClick={handleCancel}
                                                    sx={{ mt: 2, mb: 1, py: 1, px: 3.5, fontSize: 13, fontWeight: 700, display: 'inline-block', width: '100%', maxWidth: '186px', boxShadow: 'none', marginRight: '15px', color: '#0063A8', border: '1px solid #0063A8', background: '#fff' }}
                                                >
                                                    No, don't cancel
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    onClick={() => { UserDrawerClose(); setStep(0) }}
                                                    sx={{ mt: 2, mb: 1, py: 1, px: 3.5, fontSize: 13, fontWeight: 700, display: 'inline-block', width: '100%', maxWidth: '186px', boxShadow: 'none', marginLeft: 'auto' }}
                                                    fullWidth={true}
                                                >
                                                    Yes, Cancel
                                                </Button>
                                            </Box>
                                        </Box>
                                    </>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Are you sure you want to log out ?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={logout} variant="contained" color="error">
                            Logout
                        </Button>
                    </DialogActions>
                </Dialog>
            </Drawer >
            {showLoader && <PageLoader />}
            <ToastMessage />
        </>
    )
}

export default MainLayout
